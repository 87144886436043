import React, {useState, useEffect} from 'react';
import {
    makeStyles,
    shorthands,
    Body1,
  } from "@fluentui/react-components";
import StationCard from './StationCard';
export default function CompanyPage(props){
    
    const [stations, setStations] = useState([])
    useEffect(() => {
        var url = 'https://'+ window.location.hostname +':8080/getstations'
        console.log(url)
        
        var jsonObj = { 
            "Company": props.company
        }
        console.log(jsonObj.Username, jsonObj.Password)
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                console.log(data.Stations)
                setStations(data.Stations)
            })
            
        
    },[]);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Body1 style={{backgroundColor: ''}}>
            Stations
        </Body1> 
        
            
        {stations.map(s => (
            <div style={{marginTop:"20px"}}>
                <StationCard name={s} company={props.company} hStChg={props.hStChg}/>
            </div>  
        ))}
             
        </div>
    )
}