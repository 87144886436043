import React, {useState} from 'react';
import {
    makeStyles,
    shorthands,
    Title2,
    Button, 
    Subtitle1
  } from "@fluentui/react-components";
  import AruleSelectSDEF from './AruleSelectSDEF'
  import ARULEruns from './ARULEruns'
export default function ARULEPanel(props){
    const [sdef, setSdef] = useState("")
    const [refresh, setRefresh] = useState(1)
    const sdfChange=(s)=>{
        console.log(s)
        setSdef(s)
    }
    const runARULE=()=>{
        
        var url = 'https://'+ window.location.hostname +':8080/runarule'
        console.log(url)
        var jsonObj = { 
                "SDEF":sdef  
            }
        console.log(jsonObj)
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                console.log(data.Result)
                setRefresh(refresh+1)
                    //setCities(data.List)  
                })
        
    }
    return (
        <div style={{display: "flex", flexDirection: 'column', alignItems: 'flex-start'}}>
        <Title2>
            ARULE
        </Title2>
        <AruleSelectSDEF onChg={sdfChange} value={sdef}/>
        <Button size='small' appearance='primary' style={{marginTop: '10px'}} onClick={runARULE}>Run ARULE</Button>
        <Subtitle1> Output </Subtitle1>
        <ARULEruns refresh={refresh}/>
        </div>
    )
}