import React, {useState, useEffect} from 'react';
import {
    Dropdown,
    makeStyles,
    Option,
    shorthands,
    useId,
  } from "@fluentui/react-components";

  const useStyles = makeStyles({
    root: {
      // Stack the label above the field with a gap
      display: "grid",
      gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("1px"),
      maxWidth: "500px",
      marginRight: '10px'
    },
    dropdown: {
       fontSize: 'var(--fontSizeBase100)'
    }
  });

export default function CellSageDccSelect(props){
    const comboId = useId("combo-default");
    const options = ["CellSage Detailed Inputs--Cycle_Set1.txt", "CellSage Detailed Inputs--Cycle_Set2.txt", "CellSage Detailed Inputs--Cycle_Set3.txt"];
    const styles = useStyles();
    
    const [selectedOptions, setSelectedOptions] = useState([options[0]])
    
    
    const onOptionSelect = (ev, data) =>{
      console.log("OnOptionSelect")
        props.onValChange(data.selectedOptions[0])
        setSelectedOptions(data.selectedOptions);
    }
    
    
    return (
        <div className={styles.root}>
          <label id={comboId} style={{fontSize: 8,marginTop: '10px'}}>Section 1.4: Detailed Cycling Conditions (DCC)</label>
          <Dropdown
            className={styles.dropdown}
            value={props.value}
            size='small'
            onOptionSelect={onOptionSelect}
            
            >
        {options.map((option) => (
          <Option style={{fontSize: 10}}key={option}>
            
                {option}
            
          </Option>
        ))}
      </Dropdown>
        </div>
      );
    };

