import React, {useState, useEffect} from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Label} from 'recharts';
import {
    makeStyles,
    shorthands,
    Body1,
    Tab,
    TabList,
  } from "@fluentui/react-components";
  const useStyles = makeStyles({
    root: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      ...shorthands.padding("-60px", "300px"),
      rowGap: "20px",
      
    },
    tab: {
        fontSize: '8px',
        marginTop: '10px'
    }
  });

export default function CellPage(props){
    const [dur, setDur] = useState(24)
    const ontabSelect=(event, data) => {
        
        if (data.value == 'tabl') setDur(24)
        else if (data.value == 'tab2') setDur(7*24)
        else if (data.value == 'tab3') setDur(720)
        else if (data.value == 'tab4') setDur(80000)



    }
    const styles = useStyles();
    console.log("cellPage")
    const [cellData, setCellData] = useState([])
    //const [sensors, setSensors] = useState([])
    const formatXAxis = (v) =>{
        var d = new Date(0);
        d.setUTCSeconds(v);
        return d.toLocaleString();
    }
    const getCellData = () =>{
        var url = 'https://'+ window.location.hostname +':8080/getcelldata'
        console.log(url)
        
        var jsonObj = { 
            "Company": props.company,
            "Station": props.station,
            "Container": props.container,
            "Rack": props.rack,
            "Pack": props.pack,
            "Cell": props.cellID,
            "Sensor": props.sensor,
            "Hours": dur
        }
        console.log(jsonObj)
        //console.log(jsonObj.Company, jsonObj.Station, jsonObj.Container, jsonObj.Rack, jsonObj.Pack)
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setCellData(data)
                //setSensors(data.Sensors)
            })
            
    }
    useEffect(() => {
        getCellData()
    },[dur]);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Body1 style={{backgroundColor: ''}}>
        <div style={{display: 'flex', column: 'row'}}>
            <div style={{marginRight: '5px'}} onClick={props.b2St}> {props.station}</div> 
            <div style={{marginRight: '5px'}}>\</div> 
            <div style={{marginRight: '5px'}} onClick={props.b2C}>{props.container}</div> 
            <div style={{marginRight: '5px'}}>\</div> 
            <div style={{marginRight: '5px'}} onClick={props.b2R}>{props.rack}</div> 
            <div style={{marginRight: '5px'}}>\</div> 
            <div style={{marginRight: '5px'}} onClick={props.b2P}> {props.pack}</div>
            <div style={{marginRight: '5px'}}>\</div> 
            <div style={{marginRight: '5px'}}>{props.cell}</div> 
        </div>
        </Body1>
        <LineChart width={600} height={200} data={cellData}>
            <Label value="Cell Voltage" offset={0} position="top"/>
            <Line type="monotone" dataKey="Voltage" stroke="#fc5603" dot={false}/>
            
            <XAxis dataKey="Logtime" tick={{fontSize: 6}} tickFormatter={formatXAxis} />
            <YAxis domain={['auto', 'auto']} tick={{fontSize: 8}} label={{value: 'Voltage', angle: -90, position: 'insideLeft', fontSize: 12, offset: 30}}/>
        </LineChart>
        <LineChart width={600} height={200} data={cellData}>
            <Line type="monotone" dataKey="Temperature" stroke="#fcba03" dot={false}/>
            
            <XAxis dataKey="Logtime" tick={{fontSize: 6}} tickFormatter={formatXAxis}/>
            <YAxis domain={['auto', 'auto']} tick={{fontSize: 8}} label={{value: 'Temperature °C', angle: -90, position: 'insideLeft', fontSize: 12, offset: 30}}/>

        </LineChart>
        <LineChart width={600} height={200} data={cellData}>
            <Line type="monotone" dataKey="Status" stroke="#8884d8" dot={false}/>
            
            <XAxis dataKey="Logtime" tick={{fontSize: 6}} tickFormatter={formatXAxis}>
            </XAxis>
            <YAxis domain={[-1.5, 1.5]} tick={{fontSize: 8}} label={{value: 'Status', angle: -90, position: 'insideLeft', fontSize: 12, offset: 30}}/>
        </LineChart>
        <div className={styles.root}>
        <TabList defaultSelectedValue="tab1" onTabSelect={ontabSelect}>
            <Tab value="tab1"><div className={styles.tab}>Last 24 Hours</div></Tab>
            <Tab value="tab2"><div className={styles.tab}>Last 7 Days</div></Tab>
            <Tab value="tab3"><div className={styles.tab}>Last 30 Days</div></Tab>
            <Tab value="tab4"><div className={styles.tab}>All Time</div></Tab>
        </TabList>
        </div>
        </div>
    )
}