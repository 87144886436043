
import React, {useState} from 'react';
import {
  makeStyles,
  shorthands,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tab,
  TabList,
  Title2,
  Caption2Strong,
  Caption2
} from "@fluentui/react-components";

import { PersonFilled } from "@fluentui/react-icons";
import MonitorPanel from './MonitorPanel'
import CellSagePanel from './CellSagePanel'
import DRDMPanel from './DRDMPanel'
import ARULEPanel from './ARULEPanel'
/*import type {
    SelectTabData,
    SelectTabEvent,
    TabValue,
  } from "@fluentui/react-components";*/

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    backgroundColor: 'black',
    flexDirection: "row",
    justifyContent: "flex-start",
    ...shorthands.padding("50px", "0px"),
    rowGap: "80px",
    minWidth: "140px",
    maxWidth: "140px",
    minHeight: '90vh',
  },
  layout: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    //...shorthands.padding("50px", "20px"),
    rowGap: "80px",
    maxWidth: "140px",
    minHeight: '90vh',
  },
  panels: {
    backgroundColor: '',
    marginLeft: '30px',
    marginTop: '-30px',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    //...shorthands.padding("20px", "40px"),
    "& th": {
        textAlign: "left",
        ...shorthands.padding(0, "30px", 0, 0),
      },
    minWidth: "500px"
  },
  user: {
    //backgroundColor: 'green',
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    minWidth: '140px',
    maxWidth: "140px",
  }
});

export default function MainToolbar(props) {
    const styles = useStyles();
    const [currentTab, setCurrentTab] = useState("tab1")
    const ontabSelect=(event, data) => {
        setCurrentTab(data.value)
    }
    const logoutHandler = () => {
        console.log("logouthandler")
        document.cookie = "session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
        window.location.reload();
    }
    return (
      <div className={styles.root}>
        <div className={styles.layout}>
        <TabList defaultSelectedValue="tab1" vertical onTabSelect={ontabSelect}>
          <Title2 style={{marginTop: '-30px', marginLeft: '10px'}}>BDPS</Title2>
          <Tab style={{marginTop: '50px', minWidth: '90px'}} value="tab1">Monitor</Tab>
          <Tab style={{marginTop: '50px', minWidth: '90px'}} value="tab2">CellSage</Tab>
          <Tab style={{marginTop: '50px', minWidth: '90px'}} value="tab3">DRDM</Tab>
          <Tab style={{marginTop: '50px', minWidth: '90px'}} value="tab4">ARULE</Tab>
          
        </TabList>
        <div className={styles.user}>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton size="large" shape="circular" icon={<PersonFilled/>} style={{marginTop: '0px', marginLeft: "0px"}}/>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
            <Caption2 align="center">{props.user}</Caption2>
            <Caption2 align="center">{props.company}</Caption2>
        </div>
        </div>
        <div className={styles.panels}>
            {currentTab === "tab1" && <MonitorPanel company={props.company}/>}
            {currentTab === "tab2" && <CellSagePanel/>}
            {currentTab === "tab3" && <DRDMPanel/>}
            {currentTab === "tab4" && <ARULEPanel/>}
      </div>
      </div>
    );
  };