import React, {useState, useEffect} from 'react';
import {
    makeStyles,
    shorthands,
    Body1,
  } from "@fluentui/react-components";
import PackCard from './PackCard';
export default function RackPage(props){
    
    const [packs, setPacks] = useState([])
    useEffect(() => {
        var url = 'https://'+ window.location.hostname +':8080/getpacks'
        console.log(url)
        
        var jsonObj = { 
            "Company": props.company,
            "Station": props.station,
            "Container": props.container,
            "Rack": props.rack
        }
        console.log(jsonObj.Company, jsonObj.Station, jsonObj.Container)
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                console.log(data.Packs)
                setPacks(data.Packs)
            })
            
        
    },[]);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Body1 style={{backgroundColor: ''}}>
        <div style={{display: 'flex', column: 'row'}}>
            <div style={{marginRight: '5px'}}onClick={props.b2St}> {props.station}</div>
            <div style={{marginRight: '5px'}}>\</div>
            <div style={{marginRight: '5px'}} onClick={props.b2C}>{props.container}</div>
            <div style={{marginRight: '5px'}}>\</div> 
            <div style={{marginRight: '5px'}}>{props.rack}</div>
            <div> Packs</div>
        </div>
        </Body1> 
        
            
        {packs.map(s => (
            <div style={{marginTop:"20px"}}>
                <PackCard name={s} container={props.container} rack={props.rack} station={props.station} company={props.company} hPackChg={props.hPackChg}/>
            </div>  
        ))}
             
        </div>
    )
}