import React, {useState, useEffect} from 'react';
import {
    makeStyles,
    shorthands,
    Body1,
  } from "@fluentui/react-components";
import PackCard from './PackCard';
import CellCard from './CellCard';
export default function PackPage(props){
    
    const [sensors, setSensors] = useState([])
    useEffect(() => {
        var url = 'https://'+ window.location.hostname +':8080/getsensors'
        console.log(url)
        
        var jsonObj = { 
            "Company": props.company,
            "Station": props.station,
            "Container": props.container,
            "Rack": props.rack,
            "Pack": props.pack
        }
        console.log(jsonObj.Company, jsonObj.Station, jsonObj.Container, jsonObj.Rack, jsonObj.Pack)
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                console.log(data.Sensors)
                setSensors(data.Sensors)
            })
            
        
    },[]);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Body1 style={{backgroundColor: ''}}>
        <div style={{display: 'flex', column: 'row'}}>
            <div style={{marginRight: '5px'}} onClick={props.b2St}> {props.station}</div> 
            <div style={{marginRight: '5px'}}>\</div> 
            <div style={{marginRight: '5px'}} onClick={props.b2C}>{props.container}</div> 
            <div style={{marginRight: '5px'}}>\</div> 
            <div style={{marginRight: '5px'}} onClick={props.b2R}>{props.rack}</div> 
            <div style={{marginRight: '5px'}}> \ {props.pack} Cells</div>
        </div>
        </Body1>
        <div style={{marginTop:"20px"}}>
            <CellCard name="Cell 01" sensor={sensors[0]} cell={1} hCellChg={props.hCellChg}/>
        </div>
        <div style={{marginTop:"20px"}}>
            <CellCard name="Cell 02" sensor={sensors[0]} cell={2} hCellChg={props.hCellChg}/>
        </div>
        <div style={{marginTop:"20px"}}>
            <CellCard name="Cell 03" sensor={sensors[0]} cell={3} hCellChg={props.hCellChg}/>
        </div>
        <div style={{marginTop:"20px"}}>
            <CellCard name="Cell 04" sensor={sensors[0]} cell={4} hCellChg={props.hCellChg}/>
        </div>

    
        </div>
    )
}