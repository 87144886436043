import React, {useState, useEffect} from 'react';
import {
    Dropdown,
    makeStyles,
    Option,
    shorthands,
    useId,
  } from "@fluentui/react-components";

  const useStyles = makeStyles({
    root: {
      // Stack the label above the field with a gap
      display: "grid",
      gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("1px"),
      maxWidth: "500px",
      marginTop: '10px'
    },
    dropdown: {
       fontSize: 'var(--fontSizeBase100)'
    }
  });

export default function HSTempProfileSelect(props){
    const comboId = useId("combo-default");
    const options = ["1-Linear","2-Hyperbolic", "3-Parabolic"];
    const styles = useStyles();
    
    const [selectedOptions, setSelectedOptions] = useState([options[0]])
    
    
    const onOptionSelect = (ev, data) =>{
        console.log(data.selectedOptions)
        props.onValChange(data.selectedOptions[0])
        setSelectedOptions(data.selectedOptions);
    }
    
    
    return (
        <div className={styles.root}>
          <label id={comboId} style={{fontSize: 8}}>Section 5.5: HS Temperature Profile</label>
          <Dropdown
            className={styles.dropdown}
            value={props.value}
            size='small'
            onOptionSelect={onOptionSelect}
            
            >
        {options.map((option) => (
          <Option style={{fontSize: 10}} value={option}>
            
                {option}
            
          </Option>
        ))}
      </Dropdown>
        </div>
      );
    };

