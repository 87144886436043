import React, {useState, useEffect,Fragment} from 'react';
import {
    Card,
    Checkbox, MenuList
  } from "@fluentui/react-components";
export default function CellSageCityPicker(props){
    
    const [cities, setCities] = useState([])
   
    const handleChange = (e)=>{
        console.log("handleChange")
        let val = e.target.value
        let checked = e.target.checked
        let cs = cities
        cs.forEach((c)=>{
            console.log(val, c.name)
            if (val === c.name){
                console.log("matchFound")
                c.checked = checked
            } 
        })
        console.log(cs)
        setCities(cs)
        props.onValChange(cs)
    }
     

    
    useEffect(() => {
        var url = 'https://'+ window.location.hostname +':8080/getcitylist'
        console.log(url)
        //props.loginFunc

        
        
        let fetchData = {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                var profiles = []
                console.log(data.List)
                //setCities(data.List)
                data.List.map((l)=>{
                    let profile = {
                        name: l,
                        checked: false
                    }
                    profiles.push(profile)
                })
                console.log(profiles)
                setCities(profiles)
            })
            
        
    },[]);
   
    return(
        <div style={{display: 'flex', flexDirection: 'column', alignItems:'start'}}>
        <label  style={{fontSize: 8,marginTop: '0px'}}>Section 4.1: Annual Temperature Profiles</label>
        <Card style={{minWidth: '300px', minHeight:'300px', maxWidth: '300px', maxHeight:'300px'}}>
            
            <MenuList style={{display: 'flex', flexDirection: 'column', alignItems:'start', overflowY: 'scroll'}}>
            {cities.map((city) => (
            
                <Checkbox size="medium" onChange={handleChange} label={<div style={{fontSize: 10}}>{city.name}</div>} value={city.name}/>
                
            
        ))}
        </MenuList>
        </Card>
        </div>
    )
}