import React, {useState} from 'react';
import CompanyPage from './CompanyPage'
import StationPage from './StationPage'
import ContainerPage from './ContainerPage';
import RackPage from './RackPage'
import PackPage from './PackPage'
import CellPage from './CellPage'
import {
    makeStyles,
    shorthands,
    Title2,
  } from "@fluentui/react-components";


export default function MonitorPanel(props){
    const [station, setStation] = useState("")
    const [container, setContainer] = useState("")
    const [rack, setRack] = useState("")
    const [pack, setPack] = useState("")
    const [cell, setCell] = useState("")
    const [cellID, setCellID] = useState("")

    const [sensor, setSensor] = useState("")
    
    const handleStation = (s) =>{
        setStation(s)
    }
    const handleContainer = (s) =>{
        setContainer(s)
    }
    const handleRack = (s) =>{
        setRack(s)
    }
    const handlePack = (s) =>{
        
        setPack(s)
    }
    const handleCell = (s, t, u) =>{
        console.log("handle Cell")
        setSensor(t)
        setCell(s)
        setCellID(u)
    }
    const clearAll = () =>{
        setStation("")
        setContainer("")
        setRack("")
        setPack("")
        setCell("")
        setSensor("")
    }
    const goBackToStation = () =>{
        setContainer("")
        setRack("")
        setPack("")
        setCell("")
        setSensor("")

    }
    const goBackToContainer = () =>{
        setRack("")
        setPack("")
        setCell("")
        setSensor("")


    }
    const goBackToRack = () =>{
        setPack("")
        setCell("")
        setSensor("")
    }
    const goBackToPack = () =>{
        setCell("")
        setSensor("")
    }
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <Title2 onClick={clearAll}>
                {props.company}
            </Title2>
            {station === "" && <CompanyPage company={props.company} hStChg={handleStation}/>}
            {station !== "" && container === "" && <StationPage company={props.company} station={station} hConChg={handleContainer}/>}
            {container !== "" && rack === "" && <ContainerPage company={props.company} station={station} container={container} hRackChg={handleRack} b2St={goBackToStation}/>}
            {rack !== "" && pack === "" && <RackPage company={props.company} station={station} container={container}  rack={rack} hPackChg={handlePack} b2St={goBackToStation} b2C={goBackToContainer}/>}
            {pack !== "" && cell === "" && <PackPage company={props.company} station={station} container={container}  rack={rack} pack={pack} hCellChg={handleCell} b2St={goBackToStation} b2C={goBackToContainer} b2R={goBackToRack}/>}
            {cell !== "" && <CellPage company={props.company} station={station} container={container} rack={rack} pack={pack} cell={cell} cellID={cellID} sensor={sensor} hPackChg={handleCell} b2St={goBackToStation} b2C={goBackToContainer} b2R={goBackToRack} b2P={goBackToPack}/>}
        </div>
    )
}