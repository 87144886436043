import React, {useState, useEffect} from 'react';
import {
    makeStyles,
    shorthands,
    Body1,
  } from "@fluentui/react-components";
import RackCard from './RackCard';
export default function ContainerPage(props){
    
    const [racks, setRacks] = useState([])
    useEffect(() => {
        var url = 'https://'+ window.location.hostname +':8080/getracks'
        console.log(url)
        
        var jsonObj = { 
            "Company": props.company,
            "Station": props.station,
            "Container": props.container
        }
        console.log(jsonObj.Company, jsonObj.Station, jsonObj.Container)
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                console.log(data.Racks)
                setRacks(data.Racks)
            })
            
        
    },[]);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: ''}}>
        <Body1 style={{backgroundColor: ''}}>
            <div style={{display: 'flex', column: 'row'}}>
                <div style={{marginRight: '5px'}} onClick={props.b2St}>{props.station}</div>
                <div>\ {props.container} Racks</div>
            </div>
        </Body1> 
        
            
        {racks.map(s => (
            <div style={{marginTop:"20px"}}>
                <RackCard name={s} station={props.station} company={props.company} hRackChg={props.hRackChg}/>
            </div>  
        ))}
             
        </div>
    )
}