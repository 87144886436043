import React, {useState, Fragment} from 'react';
import {
    makeStyles,
    shorthands,
    Title2,
    Subtitle1,
    Checkbox, 
    Button
  } from "@fluentui/react-components";
  import CellSageTempModeSelect from './CellSageTempModeSelect'
  import CellSageChemSelect from './CellSageChemSelect'
  import CellSageDccSelect from './CellSageDccSelect'
  import CellSageDtcSelect from './CellSageDtcSelect'
  import CellSageTextBox from './CellSageTextBox'
  import CellSageCityPicker from './CellSageCityPicker'
  import HSTempProfileSelect from "./HSTempProfileSelect"
  import CellSageRuns from "./CellSageRuns"
export default function CellSagePanel(props){
    const [chemSelect, setChemSelect] = useState("LFP-graphite (A123 26650)")
    const [dccOn, setDccOn] = useState(false)
    const [dtcOn, setDtcOn] = useState(true)
    const [tmOn, setTmOn] = useState(false)
    const [dtcTime, setDtcTime] = useState("5.0")
    const [dcc, setDcc] = useState("CellSage Detailed Inputs--Cycle_Set1.txt")
    const [simTime, setSimTime] = useState("2.000")
    const [dtcOcc, setDtcOcc] = useState("Once Per Day")
    const [soc, setSoc] = useState("80.00")
    const [rcs, setRcs]= useState("1.0000")
    const [inTemp, setInTemp]= useState("20.000")
    const [tmHi, setTmHi]= useState("20.000")
    const [tmLow, setTmLow]= useState("20.000")
    const [therm, setTherm] = useState("10.000")
    const [tMode, setTmode]= useState("2.1: Single T Representative of Bulk Cell")
    const [tMaxTime, setTMaxTime]= useState("0.00")
    const [profiles, setProfiles]=useState([])
    const [nCell, setNcell]=useState("9")
    const [curr, setCurr]=useState("1.400")
    const [hs, setHs]=useState("10.00")
    const [base, setBase]=useState("30.00")
    const [startV, setStartV]=useState("3.31")
    const [stopV, setStopV]=useState("2.00")
    const [hsProf, setHsProf] = useState("1-Linear")
    const [refresh, setRefresh] = useState(1)

    const onChemChange=(s)=>{
        console.log(s)
        setChemSelect(s)}
    const onSimTimeChange=(s)=>{setSimTime(s)}
    const onSocChange=(s)=>{setSoc(s)}
    const onRcsChange=(s)=>{setRcs(s)}
    const onDccChange=(s)=>{setDcc(s)}
    const onTmodeChange=(s)=>{setTmode(s)}
    const inTempChange=(s)=>{setInTemp(s)}
    const thermChange=(s)=>{setTherm(s)}
    const onDccOnChange=(e)=>{setDccOn(e.target.checked)}
    const DtcOnChange=(e)=>{
        console.log(e.target.checked)
        setDtcOn(e.target.checked)}
    const DtcTimeChange=(s)=>{setDtcTime(s)}
    const DtcOccChange=(s)=>{setDtcOcc(s)}
    const tMaxTimeChange=(s)=>{setTMaxTime(s)}
    const profilesChange=(s)=>{setProfiles(s)}
    const tmOnChange=(s)=>{setTmOn(s)}
    const tmLowChange=(s)=>{setTmLow(s)}
    const tmHiChange=(s)=>{setTmHi(s)}
    const nCellChange=(s)=>{setNcell(s)}
    const currChange=(s)=>{setCurr(s)}
    const hsChange=(s)=>{setHs(s)}
    const baseChange=(s)=>{setBase(s)}
    const startVChange=(s)=>{setStartV(s)}
    const stopVChange=(s)=>{setStopV(s)}
    const hsProfChange=(s)=>{setHsProf(s)}
    const runSim =()=>{
        var url = 'https://'+ window.location.hostname + ':8080/runcellsage'
        console.log(url)
        //props.loginFunc

        var jsonObj = { 
            "SimTime": simTime,
            "ChemSelect": chemSelect,
            "Soc": soc,
            "Rcs": rcs,
            "DccOn": dccOn.toString(),
            "Dcc": dcc,
            "Tmode": tMode,
            "InTemp": inTemp,
            "Therm": therm,
            "DtcOn": dtcOn.toString(),
            "DtcTime": dtcTime,
            "DtcOcc": dtcOcc,
            "TmaxTime": tMaxTime,
            "Profiles": profiles,
            "TmOn": tmOn.toString(),
            "TmHi": tmHi,
            "TmLow": tmLow,
            "Ncell": nCell,
            "Curr": curr, 
            "Hs": hs, 
            "Base": base,
            "HsProf": hsProf,
            "StartV": startV,
            "StopV": stopV,
            
        }
        console.log(jsonObj)
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                console.log(data.Result)
                setRefresh(refresh+1)
                //setCities(data.List)
                
                
            })
    }
    return (
        <div style={{display: "flex", flexDirection: 'column', alignItems: 'flex-start'}}>
            <Title2 style={{marginBottom: "10px"}}>
                CellSage
            </Title2>
        <div style={{display: "flex", flexDirection: 'row', alignItems: 'flex-start', backgroundColor: ''}}>
        <div style={{display: "flex", flexDirection: 'column', alignItems: 'flex-start'}}>
            
            <CellSageChemSelect value={chemSelect} onValChange={onChemChange}/>
            <CellSageTextBox label="Section 1.1: Indicate Simulation Time Period" value={simTime} onValChange={onSimTimeChange} unit="Years"/>
            <CellSageTextBox label="Section 1.2: Input SOC Condition" value={soc} onValChange={onSocChange} unit="%"/>
            <CellSageTextBox label="Section 1.3: Indicate Relative Cycling Severity" value={rcs} onValChange={onRcsChange}/>
            
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                {dccOn === true && <CellSageDccSelect value={dcc} onValChange={onDccChange}/>}
                <Checkbox style={{fontSize: 8, marginTop: '10px'}} label={<div style={{fontSize: 8}}> Use DCC </div>} checked={dccOn} onChange={onDccOnChange}/>
            </div>
            <CellSageTempModeSelect value={tMode} onValChange={onTmodeChange} />
            {tMode === "2.1: Single T Representative of Bulk Cell" && <CellSageTextBox label="Section 2.1.1: Input Temperature Value" value={inTemp} onValChange={inTempChange} unit="°C"/>}
            {tMode === "2.1: Single T Representative of Bulk Cell" && <CellSageTextBox label="Section 2.1.2: Input Thermal At Temperature" value={therm} onValChange={thermChange} unit="°C"/>}
            
            </div>
            <div style={{display: "flex", flexDirection: 'column', alignItems: 'flex-start', marginLeft: "40px"}}>
            <Checkbox style={{fontSize: 8, marginTop: '10px'}} label={<div style={{fontSize: 8}}> Include DTC in Simulation</div>} checked={dtcOn} onChange={DtcOnChange}/>
            {dtcOn === true && <CellSageTextBox label="Section 3.1: Time For DTC Temp" value={dtcTime} onValChange={DtcTimeChange} unit="Minutes"/>}
            {dtcOn === true && <CellSageDtcSelect value={dtcOcc} onValChange={DtcOccChange}/>}
            
            {(tMode === "2.1: Single T Representative of Bulk Cell" && dtcOn === true) && <CellSageTextBox label="Section 3.3: Input Time Battery is at Tmax During a Single Duty Cycle" value={tMaxTime} onValChange={tMaxTimeChange} unit="Hours"/>}
            {tMode !== "2.1: Single T Representative of Bulk Cell" && <CellSageCityPicker value={profiles} onValChange={profilesChange}/>}
            {tMode !== "2.1: Single T Representative of Bulk Cell" && <Checkbox style={{fontSize: 8, marginTop: '10px'}} label={<div style={{fontSize: 8}}> Include DTC in Simulation</div>} checked={tmOn} onChange={tmOnChange}/>}
            {(tMode !== "2.1: Single T Representative of Bulk Cell" && tmOn === true) && <CellSageTextBox label="Section 4.2.2: TM Lower Limit" value={tmLow} onValChange={tmLowChange} unit="°C"/>}
            {(tMode !== "2.1: Single T Representative of Bulk Cell" && tmOn === true) && <CellSageTextBox label="Section 4.2.2: TM Lower Limit" value={tmHi} onValChange={tmHiChange} unit="°C"/>}
            </div>
            <div style={{display: "flex", flexDirection: 'column', alignItems: 'flex-start', marginLeft: "40px"}}>
            <CellSageTextBox label="Section 5.1: Number of Cells in String" value={nCell} onValChange={nCellChange} unit="Cells"/>
            <CellSageTextBox label="Section 5.2: Current Through String" value={curr} onValChange={currChange} unit="Amps"/>
            <CellSageTextBox label="Section 5.3: Magnitude of Hot Spot in String" value={hs} onValChange={hsChange} unit="°C"/>
            <CellSageTextBox label="Section 5.4: Baseline TM Upper Limit in String" value={base} onValChange={baseChange} unit="°C"/>
            <HSTempProfileSelect value={hsProf} onValChange={hsProfChange}/>
            <CellSageTextBox label="Section 5.6: Max Cell Starting Voltage" value={startV} onValChange={startVChange} unit="V"/>
            <CellSageTextBox label="Section 5.7: Min Cell Stopping Voltage" value={stopV} onValChange={stopVChange} unit="V"/>
            
            </div>
           
        </div>
        <div style={{minWidth: '100%', minHeight: "50px", display: 'flex', flexDirection:"column", alignItems:"flex-end"}}>
            <Button size='small' appearance='primary' style={{marginTop: '10px'}} onClick={runSim}>Run Simulation</Button>
        </div>
        <Subtitle1> Output </Subtitle1>
        <CellSageRuns refresh={refresh}/>
    </div>
        
    )
}