import React, {useState} from 'react';
import {
    makeStyles,
    shorthands,
    Title2,
  } from "@fluentui/react-components";
export default function DRDMPanel(props){
    return (
        <Title2>
            DRDM
        </Title2>
    )
}