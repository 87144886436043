import React, {useState} from "react";
import {
    makeStyles,
    Title2,
    Button,
    shorthands,
    Card,
    CardFooter,
    tokens,
    CardHeader,
    Input, Label, badgeClassNames
} from "@fluentui/react-components";
import { ArrowReplyRegular, ShareRegular } from "@fluentui/react-icons";

/*const handleLogOutClick = () => {
    document.cookie = "session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/api;"
    window.location.reload();
}*/


const useStyles = makeStyles({
    card: {
        display: 'flex',
        ...shorthands.margin("auto"),
        width: "400px",
        maxWidth: "100%",
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        display: "grid",
        maxWidth: '400px',

        alignItems: 'flex-start',
        gridRowGap: tokens.spacingVerticalXXS,
        marginTop: tokens.spacingVerticalMNudge,
        ...shorthands.padding(tokens.spacingHorizontalMNudge), 
    }, 
    fields: {
        display: "grid",
        marginTop: '-20px',
        //gridRowGap: tokens.spacingVerticalXXS,
        ...shorthands.padding(tokens.spacingHorizontalMNudge), 
    }
  });

export default function Login(props){
    const styles = useStyles();
    const handlePwChange = (event) => {
        setPassword(event.target.value)
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const handleLogin = ()=>{
        var url = 'https://'+ window.location.hostname +':8080/signin'
        console.log(url)
        //props.loginFunc
        var jsonObj = { 
            "Username": email,
            "Password": password
        }
        console.log(jsonObj.Username, jsonObj.Password)
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                //console.log(data)
                //setAuth(data.Authorized)
                //setAdmin(data.Admin)
                console.log(data.Authorized)
                console.log(data.Admin)
                if (!data.Authorized){
                    console.log('failed')
                    //setNoFail(false)
                } else {
                    //props.auth(name, data.Admin, data.Authorized)
                    props.loginFunc()
                    console.log('Success')
                } 
            })
            
        }
        

    return(
        <Card className={styles.card}>
            <CardHeader header={<Title2>BDPS Login</Title2>}/>
            <div className={styles.fields}>
                <div className={styles.input} >
                    <Label size={'small'} style={{textAlign: 'left', fontSize: '10px'}}> Username</Label>
                    <Input type={"email"} onChange={handleEmailChange}/>
                </div>
                <div className={styles.input} style={{marginTop: '-10px'}}>
                    <Label size={'small'} style={{textAlign: 'left', fontSize: '10px'}}> Password</Label>
                    <Input type={"password"} onChange={handlePwChange}/>
                </div>
            </div>
            <CardFooter>
                <Button onClick={handleLogin}>Login</Button>  
            </CardFooter>
        </Card>
    )
}