import React, {useState, useEffect} from 'react';
import {
    Field,
    makeStyles,
    shorthands,
    Input
  } from "@fluentui/react-components";

  const useStyles = makeStyles({
    root: {
      // Stack the label above the field with a gap
      display: "grid",
      gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("1px"),
      maxWidth: "500px",
    },
    dropdown: {
        button: {fontSize: 'var(--fontSizeBase100)'}
    }
  });

export default function CellSageTextBox(props){
    
    const styles = useStyles();
    //const [value, setValue] = useState(options[0])
    
    
    const onChange = (ev) =>{
        
        props.onValChange(ev.target.value)
    }
    
    
    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
        <div className={styles.root}>
          <label style={{fontSize: 8, marginTop: '10px'}} > {props.label} </label>
            <Input onChange={onChange} size='small' value={props.value} />
        </div>
        <div style={{fontSize: 8, marginLeft:0 }}>{props.unit}</div>
        </div>
      );
    };

