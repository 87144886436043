import React, {useState, useEffect,Fragment} from 'react';
import {
    Text,
   Card
  } from "@fluentui/react-components";
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { StepContext } from '@mui/material';
import './CellSageRuns.css'
export default function CellSageRuns(props){
    const [txt, setTxt] = useState('')
    function getItemLabel(item) {
        console.log(item.label)
        return 'blankl';
      }
    function getItemId(item) {
        console.log(item.label)
        return item.label;
      }
      const handleSelectedItemsChange = (event, ids) => {
        //setSelectedItems(ids);
        console.log(ids)
        if (ids.endsWith('.txt') || ids.endsWith('.csv')){
            showFile(ids)
        }
        
      };
    const  showFile= (d)=>{
        console.log("showFile", d)
        var url = 'https://'+ window.location.hostname +':8080/getfile'

        var jsonObj = { 
            "File": d
        }

        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj)
        }
         fetch(url, fetchData)
                .then(res => {
                    if (res.ok){
                 return res.text().then(text => {
                     var fileContents = text
                     console.log(fileContents)
                     fileContents = fileContents.replaceAll("\n", "\n\r")
                    setTxt(fileContents)
                 }
        )}})
                }
    const [NEW,setNEW] = useState([])
    const [loaded,setLoaded] = useState(false)

     useEffect(() => {
       if (NEW.length !== 0) setLoaded(true)
        
    },[NEW]);
    useEffect(() => {
        setLoaded(false)
        var url = 'https://'+ window.location.hostname +':8080/getruns'
        console.log(url)
        //props.loginFunc

        
        
        let fetchData = {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                var profiles = []
                var str = JSON.stringify(data)
                //setCities(data.List)\
                setNEW(JSON.parse(str))
                
                
                
            })
            
        
    },[props.refresh]);
   if (loaded){
    return(
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
        <Card >
            <div style={{display: 'flex', flexDirection: 'column', alignItems:'flex-start', minHeight: '300px', minWidth: "170px", maxWidth: "170px", maxHeight: "200px", overflowY: "scroll"}}>
            {/*<label  style={{fontSize: 8,marginTop: '0px'}}>Output Files</label>*/}
            <RichTreeView 
            items={NEW} sx={{".MuiTreeItem-content .MuiTreeItem-label": {fontSize: '0.4rem', fontWeight: 200 }, ".MuiTreeItem-content" : {maxHeight: '20px', fontSize: '0.4rem', textAlign: 'left'}, ".MuiTreeItem-content .MuiTreeItem-iconContainer svg":{fontSize: '10px'} }}
            onSelectedItemsChange={handleSelectedItemsChange}
            />
            
            </div>
        </Card>
        <Card style={{marginLeft: '10px'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems:'flex-start', minHeight: '300px', minWidth: "700px", maxWidth: "700px", maxHeight: "300px", overflowY: "scroll", whiteSpace: 'pre'}}>
                <div className={'CS-text-output'} style={{fontSize: '8px',  whiteSpace: 'pre', textAlign:'left'}}>{txt}</div>
            </div>
        </Card>
</div>
    )
    } else {
        <Fragment/>
    }
}