import React, {useState, useEffect, Fragment} from 'react';
import {
    Dropdown,
    makeStyles,
    Option,
    shorthands,
    useId,
    Button
  } from "@fluentui/react-components";

  const useStyles = makeStyles({
    root: {
      // Stack the label above the field with a gap
      display: "grid",
      gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("1px"),
      maxWidth: "500px",
    },
    dropdown: {
       fontSize: 'var(--fontSizeBase100)'
    }
  });

export default function AruleSelectSDEF(props){
    const comboId = useId("combo-default");
    //const options = ["LFP-graphite (A123 26650)", "LFP-graphite (A123 Nanophosphate 20Ah)", "LithiumCobaltOxide(18650)", "LMO-LTO(Toshiba SCiB 20 Ah)", "NCA-graphite (DOE Gen2 18650- Model 1)", "NCA-graphite (DOE Gen2 18650-Model 2)", "NMC-graphite (Panasonic UR 18650)", "NMC-graphite (Sanyo Y 18650)"];
    const styles = useStyles();
    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([options[0]])
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
      var url = 'https://'+ window.location.hostname +':8080/getsdefs'
      console.log(url)
      let fetchData = {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          
      }
      fetch(url, fetchData)
          .then(res => res.json())
          .then(data => {
              setOptions(data)
              console.log(data)
              setLoaded(true)
              props.onChg(data[0])
          })
          
      
  },[]);
    
    const onOptionSelect = (ev, data) =>{
        
        props.onChg(data.selectedOptions[0])
        setSelectedOptions(data.selectedOptions);
    }
    if (!loaded){
      return(<Fragment/>)
    }
    
    return (
        <div className={styles.root}>
          <label id={comboId} style={{fontSize: 8}}>Select SDEF</label>
          <Dropdown
            className={styles.dropdown}
            value={props.value}
            size='small'
            onOptionSelect={onOptionSelect}
            
            >
        {options.map((option) => (
          <Option style={{fontSize: 10}} value={option}>
            
                {option}
            
          </Option>
        ))}
      </Dropdown>
        </div>
      );
    };

