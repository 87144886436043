import React, {Fragment, useState, useEffect } from 'react';
import Login from './Login'
import MainToolbar from './MainToolbar'

export default function Main(props){
    const [loaded, setLoaded] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [authorized, setAuthorized] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [user, setUser] = useState('')
    const [company, setCompany] = useState('')
    
    
    useEffect(() => {
        var url = 'https://'+ window.location.hostname + ':8080/checksessiontoken'
        const stuff ={
                method: 'GET',
                mode: 'cors',
                credentials:'include'
                
            }
        fetch(url,stuff)
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.Authorized){
                        setUser(data.Username)
                        setAdmin(data.Admin)
                        setAuthorized(data.Authorized)
                        setCompany(data.Company)
                        setLoggedIn(true)
                        setLoaded(true)
                        refreshToken()
                    } else {
                        setLoggedIn(false)
                        setLoaded(true)
                    }
            })
    },[]);

    const refreshToken = () =>{
        var url = 'https://'+ window.location.hostname + ':8080/refreshsessiontoken'
        const stuff ={
            method: 'POST',
            mode: 'cors',
            credentials:'include'
        }
        fetch(url,stuff)
            .then(res=>{
                if (res.ok){
                        console.log("cookie refreshed")
                    } else {
                        console.log("Cookie refresh error")
                    }
                })
    }

    const checkAgain = () =>{
        var url = 'https://'+ window.location.hostname + ':8080/checksessiontoken'
        const stuff ={
                method: 'GET',
                mode: 'cors',
                credentials:'include'
                
            }
        fetch(url,stuff)
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.Authorized){
                        setUser(data.Username)
                        setAdmin(data.Admin)
                        setAuthorized(data.Authorized)
                        setCompany(data.Company)
                        setLoggedIn(true)
                        setLoaded(true)
                    } else {
                        setLoggedIn(false)
                        setLoaded(true)
                    }
            })
    }

    const LoginFunc =()=>{
        setLoggedIn(true)
    }

    if (loggedIn){
        return(
            <MainToolbar user={user} company={company}/>    
        )
    } else {
        return (
            <Login loginFunc={checkAgain}/>
        )
    }
}