import React, {useState, useEffect} from 'react';
import {
    makeStyles,
    shorthands,
    Body1,
  } from "@fluentui/react-components";
import ContainerCard from './ContainerCard';
export default function StationPage(props){
    
    const [containers, setContainers] = useState([])
    useEffect(() => {
        var url = 'https://'+ window.location.hostname +':8080/getcontainers'
        console.log(url)
        
        var jsonObj = { 
            "Company": props.company,
            "Station": props.station
        }
        console.log(jsonObj.Company, jsonObj.Station)
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                console.log(data.Containers)
                setContainers(data.Containers)
            })
            
        
    },[]);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Body1>
        {props.station} Containers
        </Body1> 
        
            
        {containers.map(s => (
            <div style={{marginTop:"20px"}}>
                <ContainerCard name={s} station={props.station} company={props.company} hConChg={props.hConChg}/>
            </div>  
        ))}
             
        </div>
    )
}