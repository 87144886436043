import React, {useState, useEffect} from 'react';
import {
    makeStyles,
    shorthands,
    Subtitle1,
    Card,
    CardFooter,
    tokens,
    CardHeader,
    Caption1Strong,
  } from "@fluentui/react-components";
export default function PackCard(props){
    
    const [status, setStatus] = useState("")
    const [capacity, setCapacity] = useState("")
    const handleCardClick = ()=>{
        props.hPackChg(props.name)
    }
    useEffect(() => {
        var url = 'https://'+ window.location.hostname +':8080/getpack'
        console.log(url)
        //props.loginFunc

        var jsonObj = { 
            "Company": props.company,
            "Station": props.station,
            "Container": props.container,
            "Rack": props.rack,
            "Pack": props.name
        }
        
        let fetchData = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(jsonObj),   
        }
        fetch(url, fetchData)
            .then(res => res.json())
            .then(data => {
                setCapacity(data.CurrentCap)
                setStatus(data.Status)
                
            })
            
        
    },[]);
    
    return (
        <Card onClick={handleCardClick} style={{minWidth: '400px', maxWidth: '400px'}}>
            <CardHeader
                header={
                <Subtitle1>
                    {props.name}
                </Subtitle1>
                }/>
            <Caption1Strong>
                Status: {status}
            </Caption1Strong>
            <Caption1Strong>
                Capacity: {capacity}%
            </Caption1Strong>
        </Card>
    )
}